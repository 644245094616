import * as React from "react";
import { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Container } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { FHButton } from "../FHButton";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  centre: {
    textAlign: "center",
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    alignItems: "center",
    "& span": {
      transform: "rotate(90deg)",
      color: theme.palette.secondary.main,
      display: "inline-block",
      fontWeight: "bold",
    },
    "& .expanded": {
      transform: "none",
      marginTop: "-5px",
    },
  },
  titleLink: {
    textDecoration: "none",
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  largeIcon: {
    height: 40,
    width: 40,
  },
  iconWrapper: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  childContainer: {
    marginTop: 30,
  },
  container: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  titleReOrder: {
    marginTop: theme.spacing(2),
  },
  subTitleReOrder: {
    marginBottom: 0,
  },
}));

export type NavigationContainerProps = {
  title?: string;
  titleUrl?: string;
  subTitle?: string;
  ctaText?: string;
  ctaURL?: string;
  reOrderLastMinuteBreak?: boolean;
};

export const NavigationContainer: React.FC<
  PropsWithChildren<NavigationContainerProps>
> = ({
  title,
  subTitle,
  titleUrl,
  ctaText,
  ctaURL,
  children,
  reOrderLastMinuteBreak,
}: PropsWithChildren<NavigationContainerProps>) => {
  const classes = useStyles();

  return (
    <Grid container>
      {title && (
        <Grid item xs={12} className={classes.centre}>
          {titleUrl ? (
            <a
              href={titleUrl}
              className={classes.titleLink}
              data-testid="titleLink"
            >
              <Typography
                variant="h2"
                className={classnames(classes.title, classes.iconWrapper, {
                  [classes.titleReOrder]:
                    reOrderLastMinuteBreak && title === "Last minute breaks ", //For CRO:FR-4
                })}
                data-testid="title"
              >
                {title}{" "}
                <ChevronRight color="secondary" className={classes.largeIcon} />
              </Typography>
            </a>
          ) : (
            <Typography
              variant="h2"
              data-testid="title"
              className={classnames(classes.title, {
                [classes.titleReOrder]:
                  reOrderLastMinuteBreak && title === "Last minute breaks ", //For CRO:FR-4
              })}
            >
              {title}
            </Typography>
          )}
        </Grid>
      )}
      {subTitle && (
        <Grid item xs={12}>
          <Typography
            variant="h4"
            data-testid="subTitle"
            align="center"
            className={classnames(classes.subTitle, {
              [classes.subTitleReOrder]:
                reOrderLastMinuteBreak && title === "Last minute breaks ", //For CRO:FR-4
            })}
          >
            {subTitle}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          data-testid="content"
          spacing={1}
          className={classes.childContainer}
        >
          {children}
        </Grid>
      </Grid>
      {ctaText && ctaURL && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} className={classes.buttonContainer}>
            <FHButton
              fhStyle="primary"
              data-testid="button"
              href={ctaURL}
              fullWidth
            >
              {ctaText}
            </FHButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
