import React, { PropsWithChildren } from "react";
import classnames from "classnames";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootDesktop: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.action.active}`,
    justifyContent: "center",
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: 10,
      background:
        "linear-gradient(0deg, rgba(216,216,216, .5) 0%, rgba(0, 0, 0, 0) 100%)",
      zIndex: 1,
    },
    [theme.breakpoints.down("md")]: {
      "& button": {
        width: "35%",
      },
    },
  },
  desktopHideMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  link: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.action.active,
    borderRadius: `8px 8px 0 0`,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 7.5),
    },
    "&:hover": {
      border: `2px solid ${theme.palette.action.active}`,
    },
  },
  linkRoot: {
    color: theme.palette.text.primary,
  },
  linkActive: {
    color: theme.palette.text.primary,
    borderBottomColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    marginBottom: -1,
    zIndex: 2,

    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  linkActiveRoot: {
    color: theme.palette.secondary.main,
  },
  rootMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },

    backgroundColor: theme.palette.divider,
    padding: theme.spacing(3),
  },

  mobileSelect: {
    marginTop: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    boxSizing: "border-box",

    "& > svg": {
      fill: theme.palette.secondary.main,
    },

    "& > fieldset": {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },

    "&::after": {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: `${theme.spacing(7)} solid transparent`,
      borderRight: `${theme.spacing(7)} solid transparent`,
      borderTop: `${theme.spacing(7)} solid ${theme.palette.divider}`,
      position: "absolute",
      left: "50%",
      top: "100%",
      transform: "translateX(-50%)",
    },
  },
  dropdownLink: {
    fontSize: theme.spacing(1.8),
    height: "100%",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectedMenu: {
    backgroundColor: "rgba(0,0,0,0.08) !important",
  },
}));

export type TabbedNavLink = {
  primaryLabel: string;
  secondaryLabel?: string;
  url?: string;
  onTabClick?: () => void;
  active: boolean;
};

export type TabbedNavProps = {
  links: TabbedNavLink[];
  mobileText?: string;
  mobileDropdown?: boolean;
  swapColourOnLocationSelectionControls?: boolean;
};

export const TabbedNav: React.FC<TabbedNavProps> = ({
  links,
  mobileText,
  mobileDropdown,
  swapColourOnLocationSelectionControls,
}: PropsWithChildren<TabbedNavProps>) => {
  const classes = useStyles();

  const selectValue = () => {
    const selected = links.find((link: TabbedNavLink) => link.active);

    return selected
      ? (selected.url ?? links.findIndex((link: TabbedNavLink) => link.active))
      : null;
  };

  const makeDropdownLabel = (label: string, secondaryLabel?: string) => (
    <>
      <b>{label}</b>
      {secondaryLabel && ` - ${secondaryLabel}`}
    </>
  );

  return (
    <>
      <div
        className={classnames(classes.rootDesktop, {
          [classes.desktopHideMobile]: mobileDropdown,
        })}
      >
        {links?.map((link: TabbedNavLink, index: number) => (
          <Button
            variant="outlined"
            href={link.url}
            onClick={link?.onTabClick}
            key={`link-${index}`}
            className={classnames(
              classes.link,
              swapColourOnLocationSelectionControls && classes.linkRoot,
              {
                [classes.linkActive]: link.active,
                [classes.linkActiveRoot]:
                  link.active && swapColourOnLocationSelectionControls,
              },
            )}
          >
            {link.primaryLabel}
          </Button>
        ))}
      </div>

      {mobileDropdown && (
        <div className={classes.rootMobile}>
          {mobileText && <Typography variant="h3">{mobileText}</Typography>}
          <Select
            variant="outlined"
            color="secondary"
            className={classes.mobileSelect}
            value={selectValue()}
            fullWidth
          >
            {links?.map((link: TabbedNavLink, index: number) => {
              const linkLabel = makeDropdownLabel(
                link.primaryLabel,
                link.secondaryLabel,
              );
              return (
                <MenuItem
                  key={`menu-${index}`}
                  value={link.url || index}
                  onClick={link?.onTabClick}
                  classes={{
                    selected: classes.selectedMenu,
                  }}
                >
                  {link.url ? (
                    <Link
                      href={link.url}
                      color="secondary"
                      className={classes.dropdownLink}
                      underline="none"
                    >
                      {linkLabel}
                    </Link>
                  ) : (
                    <Typography color={"secondary"}>{linkLabel}</Typography>
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      )}
    </>
  );
};
